import "../../stylesheets/bootstrap/page/index"
import Swiper, {
  Navigation,
  Pagination
} from 'swiper';
import 'swiper/swiper-bundle.min.css';
import $ from 'jquery'
$(document).ready(function () {
  Swiper.use([Navigation, Pagination])
  let swiper = new Swiper('.banners', {
    slidesPerView: 1,
    // spaceBetween: 0,
    loop: true,
    // autoHeight: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      //type: 'bullets',
    },
    autoplay: {
      delay: 3000,
    },
  });
})